body {
  background: #f2f2f2;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#root {
  display: flex;
  flex-flow: column nowrap;
}
.body {
  margin-bottom: auto;
}
.footer {
  margin: auto 10px 10px;
  font-family: monospace;
  color: #4051b5;
  font-size: 1.5rem;
  position: fixed;
  bottom: 0;
  right: 10px;
}
/* .public-DraftStyleDefault-ltr{
  text-align: center !important;
} */
.DraftEditor-root figure {
  display: flex!important;
  justify-content: center!important;
}
.cover{
  padding: 0.5rem 0;

}
.cover>.react-pdf__Document{
  margin: auto;
  overflow: hidden;
  height: 100%;
}
.react-pdf__Page > canvas{
  margin:auto;
  width: 100% !important;
}
.nav_widescreencontent{
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: space-around;

}
.nav_widescreencontent a{
  padding:.1rem !important;
  text-align: center;
}
@media (max-width: 600px){
  .nav_widescreencontent{
    display: none;
  }
  ._logo{
    width:100px
  }
}
._logo{
  width: 150px;
}
@media (max-width: 667px){
  ._logo{
    width:100px
  }
}

._title{
  /* color:#3a74a7; */
  /* text-decoration: underline; */
  cursor: pointer;
}
.react-pdf__Page__canvas{
  height: inherit !important;
}
.cover,.cover *{
  height: inherit;
}

figure > img{
  width:100% !important;
}

._coverIFRAME {
  width: 200px;
}